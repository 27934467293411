import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { ImageMap } from "@qiuz/react-image-map";
import BottomLogo from "../bottomLogo";
import { imageBaseUrl } from "../../config";
import { useNavigate } from "react-router-dom";
import PrizeTable from "./PrizeTable";

const pageImg = `${imageBaseUrl}/02_tnc-min.png`;

const TncPage = (props: any) => {
  const navigate = useNavigate();
  const mapArea = [
    {
      left: "23%",
      top: "80%",
      height: "8%",
      width: "50%",
    },
  ];

  const onMapClick = (area: any, index: any) => {
    switch (index) {
      case 0:
        navigate("/");
        break;
    }
  };

  const ImageMapComponent = React.useMemo(
    () => (
      <ImageMap
        className="usage-map"
        src={pageImg}
        map={mapArea}
        onMapClick={onMapClick}
      />
    ),
    [mapArea, pageImg]
  );

  const firstTnc = {
    section: "活動條款及細則",
    content: [
      "是次活動之主辦機構為德高貝登有限公司（下稱：德高貝登）。",
      "是次活動日期由2024年6月8日09:00開始至2024年6月16日23:59結束。",
      "活動時間以主辦機構的遊戲伺服器時間為準。",
      "參加者必須細閱「父親節活動」之條款及細則，一經參加此活動，即代表閣下同意接受活動有關條款及細則的約束。",
      "參加者可於港鐵站內使用配備上網服務的智能手機或設備，掃描德高貝登父親節活動廣告上的二維碼進入「父親節活動」網頁，指定港鐵路綫包括港島綫、南港島綫、荃灣綫、觀塘綫、東涌綫、將軍澳綫、迪士尼綫及機場快綫；或透過MTR Mobile 手機應用程式並登入「父親節活動」網頁瞭解活動詳情。",
      "參加者可在2024年6月10日09:00至2024年6月16日23:59其間下載或登入MTR Mobile應用程式參與「父親節活動」活動，詳情請參閱MTR Mobile內之使用條款。一經下載即代表閣下同意接受程式有關的使用條款，詳情請參閱MTR Mobile內之使用條款。",
      "是次活動只提供MTR Mobile中文版，MTR Mobile登記用戶如欲參加遊戲，請在MTR Mobile內選取繁體中文語言方可參與活動。",
      "參加者參加是次活動將會牽涉流動網絡數據流量，參加者的流動網絡供應商可能會就閣下的數據傳輸用量收取費用。有關詳請，請向閣下的流動電話網絡供應商查詢，主辦機構不會就有關費用負上任何責任。",
      "參加者如因參加本活動而遭受、引致或所涉及的任何損害、損失，主辦機構概不承擔任何責任或法律責任。",
      "任何有關參加是次推廣活動的懷疑或證實的詐騙個案，將可能導致參加者的登記及/或得獎者應得之獎品被即時取消及/或中止。",
      "德高貝登及有關機構之員工及家屬均不能參加是次推廣活動。",
      "德高貝登保留修改、變更、更新及終止是次活動及上述條款及細則，而無須另行通知。",
      "德高貝登對是次活動擁有最終決定權，如有任何爭議，德高貝登保留最終決定權。如有任何關於是次活動的查詢，可以電郵 info@jcdecaux.com.hk 或致電2111 0111與主辦機構聯絡。",
    ],
  };

  const secondTnc = {
    section: "分享父親節心意卡有獎遊戲",
    content: [
      "分享父親節心意卡有獎遊戲（「有獎遊戲活動」）的推廣期為2024年6月8日09:00開始至2024年6月19日23:59結束（香港時間，以系統時間為準）（「有獎遊戲活動推廣期」）。",
      "參加者必須於有獎遊戲活動推廣期內完成以下步驟方可成為合資格參加者（「合資格參加者」）：",
      "合資格參加者必須保證其提交的留言內容為本人原創之作品，絕無侵害任何第三方之權益（包括但不限知識產權），因此所衍生之法律責任，一概由合資格參加者自行承擔。",
      "合資格參加者所提交之內容如有任何抄襲、粗言穢語、不雅、渲染色情暴力或惡意批評等的內容，本機構有權將取消其參加此活動及/或獲取獎品的資格。",
      "德高貝登將從合資格參加者中選出留言最感動的100位得獎者（「得獎者」）。得獎者可獲得以下獎品（獎品）：",
      "德高貝登將於2024年7月5日或以前透過Facebook或Instagram私訊形式聯絡得獎者通知領獎詳情。得獎者須於2024年7月15日或之前直接回覆訊息及提供相關資料，以便作出領獎安排。若得獎者逾期未回覆得獎訊息，或者得獎者被發現不符合參加資格，又或者得獎者未能提供充足資料核實其身份，其得獎資格將被取消，並不作另行通知。主辦機構保留重選得獎者或取消獎品之權利。",
      "獎品不能兌換現金、不可轉讓或轉售；恕不接納變更獎品要求。",
      "獎品一經領取，若有遺失、損毀、被竊或品質差異；一概不會提供任何退換或補償。",
      "獎品圖片只供參考。",
      "任何因電腦、網路等技術問題而引致參加者所遞交的資料有遲延、遺失、錯誤、無法辨識等情況，主辦機構恕不負責。",
      "主辦機構有權不定時修改本條款及細則、更改或終止活動而毋須作出任何通知。",
      "如有任何爭議，主辦機構保留最終決定權。",
    ],
  };

  const lastTnc = {
    section: "備註事項",
    content: [
      "參加者承諾不會作出任何可能會損害本活動及主辦機構利益和／或名譽的行為或其他不當行為，包括但不限於任何 淫褻、色情、暴力、宗教、政治、影射、辱罵或不雅內容、行為或表述。未經主辦單位書面同意，參加者不得在活動中進行任何宣傳、推廣或展示任何廣告或宣傳材料。如參加者違反上述規定， 主辦機構有權不作事先通知下取消其活動參與資格，並將保留追究法律責任及／或索償之權利。",
      "參加者同意並確認，主辦機構有權（無論是自行或通過第三方）在全球範圍內，通過任何媒體和形式，以廣播、宣傳、發佈及其他與本活動相關的目的，使用以下資料、信息或文檔，而無需支付任何補償（無論以金錢或其他形式）或獲得額外許可或通知：",
      "參加者在參與「父親節活動」活動時，活動的圖片(；及／或",
      "參加者在參與「有獎遊戲活動」時所發佈的貼文；及／或",
      "參加者的名字、肖像、聲音、背景、照片、個人特徵、錄影、圖像。",
      "本活動的過程可能會被拍攝、錄影，主辦機構有權（但非義務）在世界各地的電視和其他主辦單位認為適合的媒體（包括但不限於其他電視台、電台、報紙、雜誌或 任何媒體或社交平台）上，及／或以其他任何形式廣播或發佈。所有本活動相關之知識產權，包括但不限於就本活動所錄製的影音製品的知識產權及其他一切權利，均由主辦機構永久擁有。參加者在此不可撤銷地轉讓及／或放棄有關本活動的影音錄影製品內容現有及未來的所有權利（包括但不限於版權、知識產權及精神權利）。",
      "參加者參加是次活動將會牽涉流動網絡數據流量，參加者的流動網絡供應商可能會就閣下的數據傳輸用量收取費用。有關詳請，請向閣下的流動電話網絡供應商查詢，主辦機構不會就有關費用負上任何責任。",
      "如因參加者提供不實或不正確資料而引致主辦機構或任何第三者有所損失，參加者須承擔一切相關責任。",
      "參加者如因參加本活動而遭受、引致或所涉及的任何損害、損失，主辦機構概不承擔任何責任或法律責任。",
      "主辦機構有權向其員工、廣告代理和有份參與是次活動的受聘服務提供者透露聯絡資料，用以活動推廣用途。",
      "所有從參加者取得的個人資料（如適用）在活動完結後六個月將會被銷毀。",
      "主辦機構及其市場推廣合作夥伴之員工及家屬不可參加有獎遊戲活動，以示公允。",
      "主辦機構有權不定時修改本條款及細則、更改或終止活動而毋須作出任何通知。",
      "主辦機構對是次活動擁有最終決定權，如有任何爭議，主辦機構保留最終決定權。",
    ],
  };

  return (
    <div className="main_wrapper">
      <div className="landing">
        <div className="step step_0">
          <div className="content">
            {ImageMapComponent}
            <div
              style={{
                position: "absolute",
                top: "20%",
                width: "90%",
                height: "60%",
                fontSize: "3em",
                color: "white",
                left: "5%",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontSize: "2em",
                  alignSelf: "center",
                  marginBottom: 20,
                  marginTop: 20,
                  textAlign: "center",
                }}
              >
                {firstTnc.section}
              </p>
              {firstTnc.content.map((text, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 20,
                    }}
                  >
                    <p
                      style={{
                        marginRight: "5px",
                      }}
                    >
                      {index + 1}.
                    </p>
                    <p>{text}</p>
                  </div>
                );
              })}

              {/* second one*/}
              <p
                style={{
                  fontSize: "2em",
                  alignSelf: "center",
                  marginBottom: 20,
                  marginTop: 20,
                  textAlign: "center",
                }}
              >
                {secondTnc.section}
              </p>
              {secondTnc.content.map((text, index) => {
                return (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 20,
                      }}
                    >
                      <p
                        style={{
                          marginRight: "5px",
                        }}
                      >
                        {index + 1}.
                      </p>
                      <p>{text}</p>
                    </div>
                    {index === 1 && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: 20,
                        }}
                      >
                        <p
                          style={{
                            marginRight: "5px",
                          }}
                        >
                          {"a)"}
                        </p>
                        <p>
                          將父親節心意卡分享到Instagram或Facebook（只限貼文）；及
                        </p>
                      </div>
                    )}
                    {index === 1 && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: 20,
                        }}
                      >
                        <p
                          style={{
                            marginRight: "5px",
                          }}
                        >
                          {"b)"}
                        </p>
                        <p>寫下想對爸爸說的話；及 </p>
                      </div>
                    )}
                    {index === 1 && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: 20,
                        }}
                      >
                        <p
                          style={{
                            marginRight: "5px",
                          }}
                        >
                          {"c)"}
                        </p>
                        <p>
                          標註2位朋友及加上hashtag #DaddyMySuperHero2024；及標註
                          @mtradvertisinghk
                        </p>
                      </div>
                    )}
                    {index === 1 && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "10%",
                          marginTop: 20,
                        }}
                      >
                        <p
                          style={{
                            marginRight: "5px",
                          }}
                        >
                          {"d)"}
                        </p>
                        <p>
                          若發佈於Facebook上，貼文必須設定為「公開」;若發佈於Instagram上，其Instagram帳戶必須設定為「公開」。
                        </p>
                      </div>
                    )}

                    {/* table */}

                    {index === 4 && <PrizeTable />}
                  </div>
                );
              })}

              {/* last one */}
              <p
                style={{
                  fontSize: "2em",
                  alignSelf: "center",
                  marginBottom: 20,
                  marginTop: 20,
                  textAlign: "center",
                }}
              >
                {lastTnc.section}
              </p>
              {lastTnc.content.map((text, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 20,
                    }}
                  >
                    <p
                      style={{
                        marginRight: "5px",
                      }}
                    >
                      {index + 1}.
                    </p>
                    <p>{text}</p>
                  </div>
                );
              })}
            </div>

            <BottomLogo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(TncPage);
