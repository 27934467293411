import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { ImageMap } from "@qiuz/react-image-map";
import BottomLogo from "../bottomLogo";
import { imageBaseUrl, apiUrl } from "../../config";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import LoadingIcon from "../loadingIcon";
import { getCurrentDateTime } from "../../utils";
import moment from "moment";
const pageImg = `${imageBaseUrl}/0_index-min.png`;
const endImg = `${imageBaseUrl}/pop1-min.png`;
const timeImg = `${imageBaseUrl}/pop3-min.png`;
const tncImg = `${imageBaseUrl}/tnc-min.png`;

const lines = [
  "是次活動之主辦機構為德高貝登有限公司（下稱：德高貝登）。",
  "是次活動日期由2024年7月26日開始至2024年8月11日結束，當中分三個階段進行活動，參加者可於港鐵站內使用配備上網服務的智能手機或設備，在指定港鐵路綫包括港島綫、南港島綫、荃灣綫、觀塘綫、東涌綫、將軍澳綫、迪士尼綫及機場快綫掃描德高貝登活動廣告上的二維碼進入「好動全城」投票活動網頁。階段一日期為2024年7月26日00:00開始至2024年7月31日23:59結束；階段二日期為2024年8月1日00:00至2024年8月7日23:59；階段三日期為2024年8月8日00:00至2024年8月11日23:59。",
  "階段一和階段三只有投票最支持的運動項目及填寫打氣說話環節；而階段二除了可掃描德高貝登活動廣告上的二維碼進入活動網頁，還可在2024年8月1日至2024年8月7日，每日早上09:00開始至晚上23:59透過MTR Mobile Newsfeed進入遊戲，完成投票最支持的運動項目及填寫打氣說話後有機會獲取50 MTR分。",
  "最高票數的五項運動的投票結果和打氣說話將有機會在港鐵站包括觀塘綫、將軍澳綫、荃灣綫、港島綫、南港島綫、東涌綫及迪士尼綫內的數碼屏幕上展出，投票結果和打氣說話不會即時更新在港鐵站內的數碼屏幕。",
  "活動時間以主辦機構的遊戲伺服器時間為準。",
  "參加者必須細閱「好動全城」投票活動之條款及細則，一經參加此活動，即代表閣下同意接受活動有關條款及細則的約束。",
  "參加者可於港鐵站內使用配備上網服務的智能手機或設備，透過MTR Mobile手機應用程式並登入「好動全城」階段二投票活動網頁瞭解活動詳情。",
  "參加者必須下載MTR Mobile應用程式及登記成為MTR Mobile用戶方可參與活動「好動全城」階段二投票活動，詳情請參閱MTR Mobile內之使用條款。一經下載即代表閣下同意接受程式有關的使用條款。",
  "是次活動只提供MTR Mobile中文版，MTR Mobile登記用戶如欲參加「好動全城」階段二投票活動，請在MTR Mobile內選取繁體中文語言方可參與活動。",
  "階段二: 參加者必須根據活動網站內的指示，投選最多三票您最支持的運動項目及填寫打氣說話，即可獲取50 MTR分。每日名額，先到先得，送完即止。",
  "獲獎者將於頁面內收到一個 MTR Mobile推廣代碼，獲獎者須登入MTR Mobile即日兌換該推廣代碼，獲取50 MTR分，逾期無效。每位登記用戶每日只可兌換推廣代碼一次，請小心保管推廣代碼，不設補領。",
  "MTR分不能退換、轉讓、轉售或兌換現金。",
  "如有任何關於MTR分兌換獎品詳情，MTR Mobile用戶登入，登記及技術問題，請聯絡MTR Mobile客戶服務。",
  "參加者參加是次活動將會牽涉流動網絡數據流量，參加者的流動網絡供應商可能會就閣下的數據傳輸用量收取費用。有關詳請，請向閣下的流動電話網絡供應商查詢，主辦機構不會就有關費用負上任何責任。",
  "參加者如因參加本活動而遭受、引致或所涉及的任何損害、損失，主辦機構概不承擔任何責任或法律責任。",
  "任何有關參加是次推廣活動的懷疑或證實的詐騙個案，及/或兌換MTR分的懷疑或證實的詐騙個案，將可能導致參加者的登記及/或得獎者應得之獎品被即時取消及/或中止。",
  "德高貝登有限公司及有關機構之員工及家屬均不能參加是次推廣活動。",
  "德高貝登保留修改、變更、更新及終止是次活動及上述條款及細則，而無須另行通知。",
  "德高貝登對是次活動擁有最終決定權，如有任何爭議，德高貝登保留最終決定權。如有任何關於是次活動的查詢，可以電郵 info@jcdecaux.com.hk 或致電2111 0111與主辦機構聯絡。",
];

const WelcomePage = (props: any) => {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [end, setEnd] = useState(false);
  const [redeem, setRedeem] = useState(false);
  const [notInTime, setNotInTime] = useState(false);
  const [openTnc, setOpenTnc] = useState(false);
  const [loading, setLoading] = useState(false);
  const mapArea = [
    {
      left: "25%",
      top: "60%",
      height: "7%",
      width: "52%",
    },
    {
      left: "25%",
      top: "67.5%",
      height: "8%",
      width: "52%",
    },
    {
      left: "25%",
      top: "75.5%",
      height: "8%",
      width: "52%",
    },
  ];
  const [searchParams] = useSearchParams();

  const member_id =
    searchParams.get("member_id") || localStorage.getItem("memberId");

  useEffect(() => {
    if (member_id) {
      // get the member id from url
      setLoading(true);
      localStorage.setItem("memberId", member_id);
      axios
        .post(apiUrl + "code/finished", {
          date: getCurrentDateTime().format("YYYY-MM-DD"),
        })
        .then((result) => {
          const { data } = result;
          const { finished } = data;
          setEnd(finished);
        })
        .finally(() => {
          setLoading(false);
        });

      axios
        .post(apiUrl + "code/status", {
          date: getCurrentDateTime().format("YYYY-MM-DD"),
          memberId: member_id,
        })
        .then((result) => {
          const { data } = result;
          const { redeemed } = data;
          setRedeem(redeemed);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setEnd(true);
    }
  }, [member_id]);

  const isTimeBetween12AMand9AM = (time: any) => {
    const givenTime = moment(time, "HH:mm");

    console.log("givenTime", time, givenTime.format("HH:mm"));
    const startTime = moment("00:00", "HH:mm"); // 12 AM
    const endTime = moment("09:00", "HH:mm"); // 9 AM
    return givenTime.isBetween(startTime, endTime, null, "[)");
  };

  const checkDate = () => {
    const days = [
      "01/08",
      "02/08",
      "03/08",
      "04/08",
      "05/08",
      "06/08",
      "07/08",
    ];
    return (
      days.findIndex((day) => day === getCurrentDateTime().format("DD/MM")) > -1
    );
  };

  const onMapClick = (area: any, index: any) => {
    switch (index) {
      case 0:
        navigate("/event-details");
        break;
      case 1:
        // check time and date
        // if (isTimeBetween12AMand9AM(new Date()) || !checkDate()) {
        if (isTimeBetween12AMand9AM(getCurrentDateTime().format("HH:mm"))) {
          setNotInTime(true);
          return;
        }

        if (redeem) {
          navigate("/vote-end");
          return;
        }
        if (end) {
          setPopup(true);
          return;
        }

        navigate("/vote");
        break;
      case 2:
        setOpenTnc(true);
        break;
    }
  };

  const ImageMapComponent = React.useMemo(
    () => (
      <ImageMap
        className="usage-map"
        src={pageImg}
        map={mapArea}
        onMapClick={onMapClick}
      />
    ),
    [mapArea, pageImg]
  );

  return (
    <div className="main_wrapper">
      <div className="landing">
        <div className="step step_0">
          <div
            className="content"
            style={{
              overflowY: "auto",
              height: 1800,
            }}
          >
            {ImageMapComponent}
            <BottomLogo />
            {popup && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  height: "100%",
                  width: "100%",
                }}
              >
                <img src={endImg} />
                <div
                  style={{
                    width: "22%",
                    height: "12%",
                    position: "absolute",
                    top: "27%",
                    left: "70%",
                  }}
                  onClick={() => setPopup(false)}
                />
              </div>
            )}

            {notInTime && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  height: "100%",
                  width: "100%",
                }}
              >
                <img src={timeImg} />
                <div
                  style={{
                    width: "22%",
                    height: "12%",
                    position: "absolute",
                    top: "27%",
                    left: "70%",
                  }}
                  onClick={() => setNotInTime(false)}
                />
              </div>
            )}

            {openTnc && (
              <div
                style={{
                  position: "absolute",
                  top: "-10%",
                  height: "100%",
                  width: "100%",
                }}
              >
                <img src={tncImg} />

                <div
                  style={{
                    width: "78%",
                    height: "70%",
                    position: "absolute",
                    top: "25%",
                    left: "10%",
                    overflowY: "auto",
                  }}
                >
                  {lines.map((line, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: 25,
                          fontWeight: 800,
                        }}
                      >
                        <p
                          style={{
                            fontSize: "2em",
                            color: "white",
                            marginRight: 10,
                          }}
                        >
                          {index + 1}.
                        </p>
                        <p
                          style={{
                            fontSize: "2.5em",
                            color: "white",
                          }}
                        >
                          {line}
                        </p>
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{
                    width: "22%",
                    height: "12%",
                    position: "absolute",
                    top: "12%",
                    left: "76%",
                  }}
                  onClick={() => setOpenTnc(false)}
                />
              </div>
            )}

            {loading && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0,0,0,0.5)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1000,
                }}
              >
                <LoadingIcon />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(WelcomePage);
