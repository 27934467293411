import React, { useState, useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { ImageMap } from "@qiuz/react-image-map";
import BottomLogo from "../bottomLogo";
import { imageBaseUrl } from "../../config";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { Input } from "antd";

const { TextArea } = Input;
const questionPages = `${imageBaseUrl}/sportGala2024Apoint1_voteClear-min.png`;
const selectedImage = `${imageBaseUrl}/tick-min.png`;

const QuestionPage = (props: any) => {
  const navigate = useNavigate();

  const [answers, setAnswers] = useState<string[]>([]);
  const [comments, setComments] = useState<string>("");

  const mapArea = [
    // bottom buttons
    {
      left: "13%",
      top: "78%",
      height: "6%",
      width: "37%",
    },
    {
      left: "51%",
      top: "78%",
      height: "6%",
      width: "37%",
    },
    // OPTIONS A
    {
      left: "75%",
      top: "31.5%",
      height: "2.8%",
      width: "5%",
    },
    {
      left: "75%",
      top: "34.5%",
      height: "2.8%",
      width: "5%",
    },
    {
      left: "75%",
      top: "38%",
      height: "2.8%",
      width: "5%",
    },
    {
      left: "75%",
      top: "41%",
      height: "2.8%",
      width: "5%",
    },
    {
      left: "75%",
      top: "44%",
      height: "2.8%",
      width: "5%",
    },
    {
      left: "75%",
      top: "47%",
      height: "2.8%",
      width: "5%",
    },
    {
      left: "75%",
      top: "50%",
      height: "2.8%",
      width: "5%",
    },
    {
      left: "75%",
      top: "53%",
      height: "2.8%",
      width: "5%",
    },
    {
      left: "75%",
      top: "56%",
      height: "2.8%",
      width: "5%",
    },
    {
      left: "75%",
      top: "59%",
      height: "2.8%",
      width: "5%",
    },
    {
      left: "75%",
      top: "62%",
      height: "2.8%",
      width: "5%",
    },
  ];

  const onMapClick = async (area: any, index: any) => {
    switch (index) {
      // go back
      case 0:
        navigate("/");
        break;

      // submit and next question
      case 1:
        if (answers.length === 0) {
          toast.error(
            <p
              style={{
                fontSize: "3em",
              }}
            >
              請選擇答案
            </p>
          );
          return;
        }

        if (comments.length === 0) {
          toast.error(
            <p
              style={{
                fontSize: "3em",
              }}
            >
              請留言為香港運動員打氣
            </p>
          );
          return;
        }
        // call api to submit
        await axios.post(
          "https://sportgala2024-api.jcdinteractiveads.com/vote",
          {
            optionA: answers[0],
            optionB: answers[1],
            optionC: answers[2],
            comments,
            memberId: localStorage.getItem("memberId") || "",
          }
        );

        navigate("/vote-end");

        break;

      // 劍擊
      case 2:
        handleSelectAnswer("劍擊");
        break;

      // 游泳
      case 3:
        handleSelectAnswer("游泳");
        break;

      // 滑浪風帆
      case 4:
        handleSelectAnswer("滑浪風帆");
        break;

      // 單車
      case 5:
        handleSelectAnswer("單車");
        break;

      // 羽毛球
      case 6:
        handleSelectAnswer("羽毛球");
        break;

      // 乒乓
      case 7:
        handleSelectAnswer("乒乓");
        break;

      // 體操
      case 8:
        handleSelectAnswer("體操");
        break;

      //  柔道
      case 9:
        handleSelectAnswer("柔道");
        break;

      // 賽艇
      case 10:
        handleSelectAnswer("賽艇");
        break;

      // 杋船
      case 11:
        handleSelectAnswer("帆船");
        break;

      // 三項鐵人
      case 12:
        handleSelectAnswer("三項鐵人");
        break;
    }
  };

  const ImageMapComponent = React.useMemo(
    () => (
      <ImageMap
        className="usage-map"
        src={questionPages}
        map={mapArea}
        onMapClick={onMapClick}
      />
    ),
    [mapArea]
  );

  const handleSelectAnswer = async (answer: string) => {
    let newAnswers = [...answers];

    if (newAnswers.includes(answer)) {
      newAnswers = newAnswers.filter((item) => item !== answer);
    } else {
      newAnswers = [...newAnswers, answer];
    }

    if (newAnswers.length > 3) {
      toast.error(
        <p
          style={{
            fontSize: "3em",
          }}
        >
          只能選三項
        </p>
      );
      return;
    }

    setAnswers(newAnswers);
  };

  const getTop = (option: string) => {
    switch (option) {
      // 劍擊
      case "劍擊":
        return "36%";

      // 游泳
      case "游泳":
        return "39.5%";

      // 滑浪風帆
      case "滑浪風帆":
        return "43%";

      // 單車
      case "單車":
        return "46.5%";

      // 羽毛球
      case "羽毛球":
        return "50%";

      // 乒乓
      case "乒乓":
        return "53.5%";

      // 體操
      case "體操":
        return "57%";

      // 柔道
      case "柔道":
        return "60.5%";

      // 賽艇
      case "賽艇":
        return "64%";

      // 帆船
      case "帆船":
        return "67.5%";

      // 三項鐵人
      case "三項鐵人":
        return "71%";
    }
  };

  return (
    <div className="main_wrapper">
      <div className="landing">
        <div className="step step_0">
          <div
            className="content"
            style={{
              overflowY: "auto",
              height: 1800,
              position: "relative",
            }}
          >
            {ImageMapComponent}

            <TextArea
              style={{
                position: "absolute",
                height: "8%",
                width: "68.2%",
                left: "15.5%",
                top: "80.2%",
                fontSize: "2em",
              }}
              onChange={(e) => setComments(e.target.value)}
              maxLength={50}
            />

            {answers.map((answer) => {
              return (
                <img
                  src={selectedImage}
                  style={{
                    position: "absolute",
                    height: "2.8%",
                    width: "5%",
                    left: "75%",
                    top: getTop(answer),
                  }}
                  onClick={() => handleSelectAnswer(answer)}
                />
              );
            })}
          </div>
          <BottomLogo />
        </div>
      </div>
    </div>
  );
};

export default observer(QuestionPage);
