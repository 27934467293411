import React from "react";
import { Provider } from "mobx-react";
import Routers from "./router";
import { stores, StoresContext } from "./stores";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Provider {...stores}>
      <StoresContext.Provider value={stores}>
        <Routers />
        <ToastContainer draggable />
      </StoresContext.Provider>
    </Provider>
  );
}

export default App;
