// ./src/stores/index.ts
import { createContext, useContext } from "react";

function createStores() {
  return {};
}

const stores = createStores();

const StoresContext = createContext(stores);

const useStores = () => useContext(StoresContext);

export { stores, StoresContext };
