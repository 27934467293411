import React from "react";
import "./PrizeTable.css";

const PrizeTable = () => {
  return (
    <div className="table-container">
      <table className="prize-table">
        <thead>
          <tr>
            <th>獎項</th>
            <th>獎品</th>
            <th>獎品價值</th>
            <th>數量</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>頭 50 名</td>
            <td>Return 回本關節筋肌配方 240 粒</td>
            <td>HK$399</td>
            <td>50</td>
          </tr>
          <tr>
            <td>其後的 50 名</td>
            <td>七海健絡康復草天然減痛膏 50 克</td>
            <td>HK$119</td>
            <td>50</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PrizeTable;
