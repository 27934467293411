const termsAndConditions = [
  "是次活動之主辦機構為德高貝登有限公司（下稱：德高貝登）。",
  "是次活動日期由2023年8月9日 早上09:00至2023年8月29日晚上11:59結束。",
  "活動時間以主辦機構的遊戲伺服器時間為準。",
  "參加者必須細閱「划艇尋寶遊戲」之條款及細則，一經參加此活動，即代表閣下同意接受活動有關條款及細則的約束。",
  "參加者可於港鐵站內使用配備上網服務的智能手機或設備，透過指定港鐵站﹙港鐵廣告路綫包括觀塘綫、將軍澳綫、荃灣綫、港島綫、南港島綫、東涌綫及迪士尼綫﹚廣告專區， 掃瞄QR Code並登入「划艇尋寶遊戲」網頁瞭解活動詳情。",
  "在2023年8月9日 早上09:00至2023年8月29日晚上11:59，參加者根據活動網站內的指示，並於遊戲指定時間內完成遊戲及取得指定分數，即可贏取30 MTR分。獲獎者需要在2023年8月29日晚上11:59前完成換領MTR分程序，逾期無效。",
  "獲獎者將於頁面內收到一個 MTR Mobile推廣代碼，獲獎者須登入MTR Mobile即日兌換該推廣代碼，獲取30 MTR分，逾期無效。",
  "參加者必須下載MTR Mobile應用程式及登記成為MTR Mobile用戶方可參與活動，詳情請參閱MTR Mobile內之使用條款。一經下載即代表閣下同意接受程式有關的使用條款。",
  "每位MTR Mobile登記用戶每日只可使用推廣代碼兌換MTR分一次，請小心保管推廣代碼，不設補領。",
  "MTR分不能退換、轉讓、轉售或兌換現金。",
  "如有任何關於MTR分兌換獎品詳情，MTR Mobile 用戶登入，登記及技術問題，請聯絡MTR Mobile客戶服務。",
  "參加者參加是次活動將會牽涉流動網絡數據流量，參加者的流動網絡供應商可能會就閣下的數據傳輸用量收取費用。有關詳請，請向閣下的流動電話網絡供應商查詢，主辦機構不會就有關費用負上任何責任。",
  "如因參加者提供不實或不正確資料而引致主辦機構或任何第三者有所損失，參加者須承擔一切相關責任。",
  "參加者如因參加本活動而遭受、引致或所涉及的任何損害、損失，主辦機構概不承擔任何責任或法律責任。",
  "任何有關參加是次推廣活動的懷疑或證實的詐騙個案，及/或兌換MTR分的懷疑或證實的詐騙個案，將可能導致參加者的登記及/或得獎者應得之獎品被即時取消及/或中止。",
  "主辦機構有權向其代理人、員工和有份參與是次活動的受聘服務提供者透露聯絡資料，用以活動推廣用途。",
  "德高貝登有限公司及有關機構之員工及家屬均不能參加是次推廣活動。",
  "德高貝登保留修改、變更、更新及終止是次活動及上述條款及細則，而無須另行通知。",
  "德高貝登對是次活動擁有最終決定權，如有任何爭議，德高貝登保留最終決定權。如有任何關於是次活動的查詢，可以電郵 info@jcdecaux.com.hk 或致電2111 0111與主辦機構聯絡.",
];

const imageBaseUrl = "https://cdn.jcdinteractiveads.com/sportgala2024";

const apiUrl = "https://sportgala2024-api.jcdinteractiveads.com/";

export { termsAndConditions, imageBaseUrl, apiUrl };
