import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { ImageMap } from "@qiuz/react-image-map";
import BottomLogo from "../bottomLogo";
import { imageBaseUrl, apiUrl } from "../../config";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import LoadingIcon from "../loadingIcon";
import { getCurrentDateTime } from "../../utils";

const pageImg = `${imageBaseUrl}/2_voteEnd2-min.png`;

// const pageImg = `${imageBaseUrl}/2_voteEnd-min.png`;

const VoteEndPage = (props: any) => {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [firstApiCompleted, setFirstApiCompleted] = useState(false);
  const [loading, setLoading] = useState(false);

  const mapArea = [
    {
      left: "78%",
      top: "42%",
      height: "6%",
      width: "15%",
    },
    {
      left: "33%",
      top: "75%",
      height: "8%",
      width: "35%",
    },
    {
      left: "6%",
      top: "49%",
      height: "8%",
      width: "88%",
    },
  ];

  useEffect(() => {
    setLoading(true);
    // going to get the code
    axios
      .post(apiUrl + "sender", {
        memberId: localStorage.getItem("memberId"),
        date: getCurrentDateTime().format("YYYY-MM-DD"),
      })
      .then((result) => {
        setFirstApiCompleted(true); // Set the state to true after the first API call completes
      })
      .catch((e) => {
        setFirstApiCompleted(true); // Set the state to true after the first API call completes
      });
  }, []);

  useEffect(() => {
    if (firstApiCompleted) {
      const intervalId = setInterval(() => {
        axios
          .post(apiUrl + "code", {
            memberId: localStorage.getItem("memberId"),
            date: getCurrentDateTime().format("YYYY-MM-DD"),
          })
          .then((result) => {
            if (result.data.code) {
              setCode(result.data.code);
              setLoading(false);
            }
          })
          .catch((e) => {
            // console.error("Error fetching code:", e);
          });
      }, 3000);

      // Stop calling the API once the code is retrieved
      if (code) {
        clearInterval(intervalId);
      }

      return () => clearInterval(intervalId);
    }
  }, [firstApiCompleted, code]);

  function copy(text: string) {
    var input = document.createElement("input");
    input.setAttribute("value", text);
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand("copy");
    document.body.removeChild(input);
    return result;
  }

  const onMapClick = (area: any, index: any) => {
    switch (index) {
      case 0:
        copy(code);
        toast(
          <p
            style={{
              fontSize: "3em",
            }}
          >
            成功複製兌換碼
          </p>
        );
        break;
      case 1:
        navigate("/");
        break;
      case 2:
        if (!code) {
          toast.error("請稍等");
          return;
        }
        window.open(
          `https://link.mtrmb.mtr.com.hk/moblink/?promotioncode/?code=${code}`,
          "_blank"
        );
        return;
    }
  };

  const ImageMapComponent = React.useMemo(
    () => (
      <ImageMap
        className="usage-map"
        src={pageImg}
        map={mapArea}
        onMapClick={onMapClick}
      />
    ),
    [mapArea, pageImg]
  );

  return (
    <div className="main_wrapper">
      <div className="landing">
        <div className="step step_0">
          <div
            className="content"
            style={{
              overflowY: "auto",
            }}
          >
            {ImageMapComponent}
            <BottomLogo />
            <div
              style={{
                position: "absolute",
                top: "44%",
                left: "10%",
                letterSpacing: "1em",
              }}
            >
              <p
                style={{
                  fontSize: "3em",
                }}
              >
                {code}
              </p>
            </div>

            {loading && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0,0,0,0.5)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1000,
                }}
              >
                <LoadingIcon />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(VoteEndPage);
