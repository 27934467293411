import * as React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import WelcomePage from "./pages/welcome";
import QuestionPage from "./pages/question";
import TncPage from "./pages/tnc";
import EventDetailsPage from "./pages/eventDetails";
import VoteEndPage from "./pages/voteEnd";

// question and mtr point
export default function Routers() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<WelcomePage />} />
        <Route path="/vote" element={<QuestionPage />} />
        <Route path="/tnc" element={<TncPage />} />
        <Route path="/event-details" element={<EventDetailsPage />} />
        <Route path="/vote-end" element={<VoteEndPage />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}
