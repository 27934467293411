import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { ImageMap } from "@qiuz/react-image-map";
import BottomLogo from "../bottomLogo";
import { imageBaseUrl } from "../../config";
import { useNavigate } from "react-router-dom";
const pageImg = `${imageBaseUrl}/3_info-min.png`;

const EventPage = (props: any) => {
  const navigate = useNavigate();
  const mapArea = [
    {
      left: "23%",
      top: "97%",
      height: "2%",
      width: "50%",
    },
  ];

  const onMapClick = (area: any, index: any) => {
    switch (index) {
      case 0:
        navigate("/");
        break;
    }
  };

  const ImageMapComponent = React.useMemo(
    () => (
      <ImageMap
        className="usage-map"
        src={pageImg}
        map={mapArea}
        onMapClick={onMapClick}
      />
    ),
    [mapArea, pageImg]
  );

  return (
    <div className="main_wrapper">
      <div className="landing">
        <div className="step step_0">
          <div
            className="content"
            style={{
              overflowY: "auto",
              height: 1600,
            }}
          >
            {ImageMapComponent}
          </div>
        </div>
      </div>
      <BottomLogo />
    </div>
  );
};

export default observer(EventPage);
