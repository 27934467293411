import React from "react";
import { observer } from "mobx-react";
import bgImage from "./bannerBG.jpg";
import { imageBaseUrl } from "../../config";
const BottomLogo = ({ bottom = 0 }: any) => {
  return (
    <div
      className="logo_carousel"
      style={{
        background: `url(${bgImage})`,
        bottom: bottom,
      }}
    >
      <div className="carousel_banner" style={{ marginTop: 50 }}>
        <img
          style={{ maxWidth: "none" }}
          src={`${imageBaseUrl}/clientLogo-min.png`}
        />
      </div>
    </div>
  );
};

export default observer(BottomLogo);
