import React, { useState, useEffect } from "react";
import "./index.css";
const LoadingIcon = () => {
  return (
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoadingIcon;
